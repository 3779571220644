import React from "react";

export const Pointer = () => (
  <svg width="29" height="57" viewBox="0 0 29 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      id="PointerExpand"
      x="-1.41421"
      width="6"
      height="6"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.242 5)"
      strokeWidth="2"
    />
    <rect
      id="PointerHead"
      width="8"
      height="8"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 17.6562 6)"
    />
    <path id="PointerLeg" d="M28 57.5V34L12 18" strokeWidth="2" transform="translate(0 -12)" />
  </svg>
);
