import React, { FC, useEffect, useRef } from "react";
import { Modal } from "../../../react-components/Modal/Modal";

export interface FormModalProps {
  id: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  header: string;
  onModalContentMoved: () => void;
}

export const FormModal: FC<FormModalProps> = ({
  id,
  isOpen,
  setIsOpen,
  header,
  onModalContentMoved,
}) => {
  const modal = useRef<Element | null>();

  // TODO Below timeout needs to be set because EPiForms runs JS script after loading a page and configures form.
  // It needs to be investigated to find better solution.
  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector(`.FormModal__modalContent--${id}`);
      modal.current = el;
      onModalContentMoved();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const modalContent = document.querySelector("#FormModal__content");
        if (!modalContent || !modal.current) return;
        modalContent.replaceChildren(modal.current ?? "");
      }, 1);
    }
  }, [modal, isOpen]);

  return (
    <Modal
      className="FormModal"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Modal.Body>
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <div id="FormModal__content"></div>
      </Modal.Body>
    </Modal>
  );
};
