import React, { ReactNode } from "react";
import { FiltersModal } from "../../react-components/Modal/FiltersModal/FiltersModal";
import { useSelectValues } from "../../react-components/Inputs/hooks/useSelectValues";
import {
  GroupType,
  InputGroupAccordion,
} from "../../react-components/Inputs/InputGroupAccordion/InputGroupAccordion";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { ResourcesFilterViewModel } from "./ViewModels/ResourcesFilterViewModel.csharp";

interface ResourcesFiltersModalProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  selectedTopics: SelectOption[];
  setSelectedTopics: (newValues: SelectOption[]) => void;
  selectedBusinessAreas: SelectOption[];
  setSelectedBusinessAreas: (newValues: SelectOption[]) => void;
  topicFilters: ResourcesFilterViewModel;
  businessAreaFilters: ResourcesFilterViewModel;
  resetFiltersButton: ((onClearFilters: () => void) => ReactNode) | boolean;
}

export const ResourcesFiltersModal = ({
  isOpen,
  setOpen,
  selectedTopics,
  setSelectedTopics,
  selectedBusinessAreas,
  setSelectedBusinessAreas,
  topicFilters,
  businessAreaFilters,
  resetFiltersButton,
}: ResourcesFiltersModalProps) => {
  const topics = useSelectValues({
    currentValues: selectedTopics,
    handleSave: setSelectedTopics,
  });

  const businessAreas = useSelectValues({
    currentValues: selectedBusinessAreas,
    handleSave: setSelectedBusinessAreas,
  });

  const handleModalClose = () => {
    setOpen(false);

    topics.saveOptions();
  };

  const handleClearFilters = () => {
    topics.clearOptions();
  };

  const filtersCount = topics.values.length;

  return (
    <FiltersModal
      id="ResourcesFiltersModal"
      isOpen={isOpen}
      onClose={handleModalClose}
      resetFiltersButton={!!filtersCount && resetFiltersButton}
      onClearFilters={handleClearFilters}
      filtersCount={{ label: "filters", count: filtersCount, variant: "bold" }}
    >
      <InputGroupAccordion
        id="ResourcesFiltersModalAccordionGroupTopic"
        name="topic"
        label={businessAreaFilters.title}
        currentValues={businessAreas.values}
        options={businessAreaFilters.optionViewModel}
        groupType={GroupType.Checkbox}
        onChange={businessAreas.setValues}
      />
      <InputGroupAccordion
        id="ResourcesFiltersModalAccordionGroupTopic"
        name="topic"
        label={topicFilters.title}
        currentValues={topics.values}
        options={topicFilters.optionViewModel}
        groupType={GroupType.Checkbox}
        onChange={topics.setValues}
      />
    </FiltersModal>
  );
};
