import React from "react";
import { SideTextHotSpotVariantBViewModel } from "./SideTextHotSpotVariantBViewModel.csharp";
import { HotSpotPreviewButton } from "./HotSpotPreviewButton";
import classNames from "classnames";

export interface HotSpotProps {
  viewModel: SideTextHotSpotVariantBViewModel;
  onClick: () => void;
  mobilePreviewText: string;
  mobileOnClick: () => void;
}

export const HotSpot: React.FC<HotSpotProps> = ({
  viewModel: { x, y, previewText, dotColor },
  onClick,
  mobilePreviewText,
  mobileOnClick,
}) => {
  return (
    <div
      className={`SideTextHotSpotVariantB__Container SideTextHotSpotVariantB__Container--${dotColor}`}
      style={{ left: `${x}%`, top: `${y}%` }}
    >
      <HotSpotPreviewButton
        onClick={onClick}
        previewText={previewText}
        className="SideTextHotSpotVariantB__Container"
      />
      <HotSpotPreviewButton
        onClick={mobileOnClick}
        previewText={mobilePreviewText}
        className={classNames(
          "SideTextHotSpotVariantB__Container",
          "SideTextHotSpotVariantB__Container--mobile",
        )}
      />
    </div>
  );
};
