import React, { FC } from "react";
import { ComponentProps } from "../ComponentProps.csharp";
import { ResourceTile } from "../../Pages/ResourcesPage/ResourceTile";
import { ContentListSlider } from "../ContentList/Slider/ContentListSlider";
import { SplideSlide } from "@splidejs/react-splide";
import { ArrowsPosition } from "../ContentList/Slider/ArrowsPosition.csharp";
import { RelatedContentProps } from "../../Common/Navbar/RelatedContentProps.csharp";
import { LinkBlockWithImage } from "../LinkBlock/LinkBlockWithImage";

type ResourcesSectionListProps = ComponentProps<RelatedContentProps>;

export const ResourcesSectionList: FC<ResourcesSectionListProps> = ({
  model: { content, pictureProfile },
}) => {
  if (content.length === 0) return null;

  return (
    <ContentListSlider className="ResourcesSectionList" arrowsPosition={ArrowsPosition.Bottom}>
      {content.map((resource, index) => (
        <SplideSlide key={index} className="ResourcesSectionList__item">
          <LinkBlockWithImage
            model={{
              title: resource.title,
              description: resource.excerpt,
              linkUrl: resource.url,
              image: resource.picture,
              imageProfile: pictureProfile,
            }}
          />
        </SplideSlide>
      ))}
    </ContentListSlider>
  );
};
