import React from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { ProductIntroductionSectionViewModel } from "../ProductIntroductionSectionViewModel.csharp";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";

export const ProductIntroductionSection = ({
  model: { introduction, introductionTitle, awardsTitle, awards, productImage },
}: ComponentProps<ProductIntroductionSectionViewModel>) => {
  return (
    <div className="ProductIntroductionSection__container">
      {introduction && (
        <div className="ProductIntroductionSection__introWrapper">
          <h2 className="ProductIntroductionSection__introductionHeader">{introductionTitle}</h2>
          <p className="ProductIntroductionSection__introduction">{introduction}</p>
        </div>
      )}
      {!!productImage && (
        <div className="ProductIntroduction__productImage">
          <ResponsivePicture profile={productImage.profile} model={productImage.model} />
        </div>
      )}
      {!!awards?.length && (
        <section className="ProductIntroductionSection__awards">
          <h2 className="ProductIntroductionSection__awardsHeading">{awardsTitle}</h2>
          <ul className="ProductIntroductionSection__awardsList">
            {awards.map((award, idx) => (
              <li key={`award_${idx}`} className="ProductIntroductionSection__awardsItem">
                <div className="ProductIntroductionSection__awardsItemImageWrapper">
                  {award.logoUrl && (
                    <img
                      src={award.logoUrl}
                      alt={award.title}
                      className="ProductIntroductionSection__awardsItemImage"
                    />
                  )}
                </div>
                <p className="ProductIntroductionSection__awardsItemName">{award.title}</p>
                {award.subtitle && (
                  <p className="ProductIntroductionSection__awardsItemDate">{award.subtitle}</p>
                )}
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};
