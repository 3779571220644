import React from "react";

interface HotSpotPreviewButtonProps {
  onClick: () => void;
  previewText: string;
  className: string;
}

export const HotSpotPreviewButton: React.FC<HotSpotPreviewButtonProps> = ({
  onClick,
  previewText,
  className,
}) => {
  return (
    <button className={className} onClick={onClick}>
      <div className="SideTextHotSpotVariantB__PreviewAnimatedContainer">
        <div className="SideTextHotSpotVariantB__PreviewAnimation" />
        <div className="SideTextHotSpotVariantB__PreviewButton">+</div>
      </div>
      <span className="SideTextHotSpotVariantB__PreviewText">{previewText}</span>
    </button>
  );
};
