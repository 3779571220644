import React from "react";
import { ArrowDirection } from "../../../../../Models/Blocks/ImageHotSpots/HotSpots/ArrowDirection.csharp";
import { Pointer } from "./Pointer";

interface VesselHotSpotLineProps {
  direction: ArrowDirection;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  color: any;
}

export const Line: React.FC<VesselHotSpotLineProps> = ({ direction }) => {
  return (
    <>
      {direction === ArrowDirection.LeftUp && (
        <div
          className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--leftUp
        `}
        >
          <Pointer />
        </div>
      )}

      {direction === ArrowDirection.RightUp && (
        <div
          className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--rightUp
        `}
        >
          <Pointer />
        </div>
      )}

      {direction === ArrowDirection.LeftDown && (
        <div
          className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--leftDown
        `}
        >
          <Pointer />
        </div>
      )}

      {direction === ArrowDirection.RightDown && (
        <div
          className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--rightDown
        `}
        >
          <Pointer />
        </div>
      )}
    </>
  );
};
