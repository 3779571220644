import React, { FormEvent, useState } from "react";
import { CheckboxSelect } from "../../react-components/Inputs/CheckboxSelect/CheckboxSelect";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { useIsBelowScreenSize } from "../../react-components/useIsBelowScreenSize";
import { ButtonOutlined } from "../../react-components/Buttons/ButtonOutlined";
import { ResourcesFiltersModal } from "./ResourcesFiltersModal";
import { SortBy } from "../../react-components/Icons/SortBy";
import { FiltersCount } from "../../react-components/FiltersCount/FiltersCount";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { ResourcesFiltersViewModel } from "./ViewModels/ResourcesFiltersViewModel.csharp";

interface ResourcesFiltersProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  selectedTopics: SelectOption[];
  setSelectedTopics: (newValues: SelectOption[]) => void;
  selectedBusinessAreas: SelectOption[];
  setSelectedBusinessAreas: (newValues: SelectOption[]) => void;
  filters: ResourcesFiltersViewModel;
}

export const ResourcesFilters = ({
  handleSubmit,
  selectedTopics,
  setSelectedTopics,
  selectedBusinessAreas,
  setSelectedBusinessAreas,
  filters: { topic: topicFilters, businessArea: businessAreaFilters },
}: ResourcesFiltersProps) => {
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = useIsBelowScreenSize("mobile");

  const toggleModal = () => setShowFilters((filters) => !filters);

  const handleClearFilters = () => {
    setSelectedTopics([]);
    setSelectedBusinessAreas([]);
  };

  const resetFiltersButton = (onClearFilters: () => void) => (
    <ButtonGhost iconPosition="left" icon={Reset} mode="light" onClick={onClearFilters}>
      Reset filters
    </ButtonGhost>
  );

  const filtersCount = selectedTopics.length + selectedBusinessAreas.length;

  return (
    <div className="Resources__filters">
      <form className="Resources__form" onSubmit={handleSubmit}>
        {isMobile ? (
          <>
            <ButtonOutlined
              className="Resources__filtersButton"
              icon={SortBy}
              iconPosition="right"
              onClick={toggleModal}
            >
              Show filters
              <FiltersCount count={selectedTopics.length} />
            </ButtonOutlined>
            <ResourcesFiltersModal
              isOpen={isMobile && showFilters}
              setOpen={setShowFilters}
              selectedTopics={selectedTopics}
              setSelectedTopics={setSelectedTopics}
              selectedBusinessAreas={selectedBusinessAreas}
              setSelectedBusinessAreas={setSelectedBusinessAreas}
              topicFilters={topicFilters}
              businessAreaFilters={businessAreaFilters}
              resetFiltersButton={resetFiltersButton}
            />
          </>
        ) : (
          <>
            <div className="Resources__filtersColumn">
              <CheckboxSelect
                label={businessAreaFilters.title}
                placeholder={businessAreaFilters.title}
                name="businessArea"
                className="Resources__filter"
                options={businessAreaFilters.optionViewModel}
                currentValues={selectedBusinessAreas}
                onChange={setSelectedBusinessAreas}
              />
              <CheckboxSelect
                label={topicFilters.title}
                placeholder={topicFilters.title}
                name="topic"
                className="Resources__filter"
                options={topicFilters.optionViewModel}
                currentValues={selectedTopics}
                onChange={setSelectedTopics}
              />
            </div>
            {!!filtersCount && (
              <div className="Resources__filtersColumn">
                {resetFiltersButton(handleClearFilters)}
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
};
