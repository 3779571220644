import React, { ReactElement, useRef, useState } from "react";
import { InteractiveHeroHotSpotImage } from "./InteractiveHero/InteractiveHeroHotSpotImage";
import { InteractiveHeroHotSpotViewModel } from "./InteractiveHero/InteractiveHeroHotSpotViewModel.csharp";
import { ImageHotSpotsProps } from "./ImageHotSpotsProps.csharp";
import { SideTextHotSpotsImage } from "./SideText/SideTextHotSpotsImage";
import { SideTextHotSpotViewModel } from "./SideText/SideTextHotSpotViewModel.csharp";
import { LabeledHotSpot } from "./LabeledHotSpot/LabeledHotSpot";
import { LabeledHotSpotBlockViewModel } from "./LabeledHotSpot/LabeledHotSpotBlockViewModel.csharp";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import classNames from "classnames";

export const ImageHotSpots: React.FC<ImageHotSpotsProps> = (props: ImageHotSpotsProps) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const { pictureProps } = props;
  const { viewName, hotSpots, containToLayout } = props.model;
  const [bottomContent, setBottomContent] = useState<ReactElement>();

  const scrollToImage = () => {
    imageRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  let specializedHotSpotsComponent = null;
  switch (viewName) {
    case "InteractiveHero":
      specializedHotSpotsComponent = (
        <InteractiveHeroHotSpotImage hotSpots={hotSpots as InteractiveHeroHotSpotViewModel[]} />
      );
      break;
    case "SideTextVariantA":
    case "SideTextVariantB":
      specializedHotSpotsComponent = (
        <SideTextHotSpotsImage
          hotSpots={hotSpots as SideTextHotSpotViewModel[]}
          viewType={viewName}
          scrollToImage={scrollToImage}
          setMobileDescriptionBox={setBottomContent}
        />
      );
      break;
    case "LabeledHotSpotBlock":
      specializedHotSpotsComponent = (
        <LabeledHotSpot hotSpots={hotSpots as LabeledHotSpotBlockViewModel[]} />
      );
      break;
  }

  const renderContent = (
    <>
      {specializedHotSpotsComponent}
      <ResponsivePicture {...pictureProps} />
    </>
  );

  return (
    <>
      <div
        className={classNames("ImageHotSpots__Container", {
          "ImageHotSpots__Container--limited": containToLayout,
        })}
      >
        {containToLayout ? (
          <div className="ImageHotSpots__ContainerInner">{renderContent}</div>
        ) : (
          renderContent
        )}
      </div>
      {bottomContent}
    </>
  );
};
