import { SegmentProps } from "../Pages/VesselSegmentsLandingPage/VesselListing/SegmentProps.csharp";
import { VesselGroupProps } from "../Pages/VesselSegmentsLandingPage/VesselListing/VesselGroupProps.csharp";
import { VesselProps } from "../Pages/VesselSegmentsLandingPage/VesselListing/VesselProps.csharp";
import { TabSection } from "./TabsListing/TabsListing";

export const formatVesselsForPlainList = (vessels: VesselGroupProps[]): VesselProps[] => {
  return vessels.flatMap((vg) => vg.items).sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
};

export const formatVesselsForSegments = (
  segments: SegmentProps[],
  vessels: VesselGroupProps[],
): TabSection[] => {
  const listOfVessels = formatVesselsForPlainList(vessels);
  return segments
    .map((segment) => ({
      title: segment.name,
      description: segment.description,
      readMoreLabel: segment.readMoreLabel,
      readMoreUrl: segment.readMoreUrl,
      items: listOfVessels
        .filter((vessel) => segment.vesselIds.includes(vessel.id))
        .filter((vessel): vessel is VesselProps => vessel !== undefined)
    }))
    .filter((segment) => !!segment.items.length);
};

export const formatVesselsForAlphabet = (vessels: VesselGroupProps[]): TabSection[] => {
  return vessels.map((vg) => ({
    title: vg.letter,
    items: vg.items,
  }));
};

export const filterOutVesselsNotIncludedInSegments = (
  segments: SegmentProps[],
  vessels: VesselProps[],
): VesselProps[] => {
  const vesselIdsInSegments = segments.flatMap((segment) => segment.vesselIds);
  return vessels.filter((vessel) => !vesselIdsInSegments.includes(vessel.id));
};
