import classNames from "classnames";
import React from "react";
import { Count } from "../../../../react-components/Atoms/Count/Count";
import { PictureProfileDto } from "../../../../Images/Resizing/PictureProfileDto.csharp";
import { ProductCardViewModel } from "../../../Product/ProductListingTile/ProductCardViewModel.csharp";
import { SimpleTile } from "../../../../react-components/TileGrid/Tiles/SimpleTile/SimpleTile";

interface ProductsListingProductsProps {
  products: ProductCardViewModel[];
  productCardPictureProfile: PictureProfileDto;
  fullWidth?: boolean;
  showNativeProductsCount?: boolean;
}

export const ProductsListingProducts = ({
  products,
  productCardPictureProfile,
  fullWidth = false,
  showNativeProductsCount = false,
}: ProductsListingProductsProps) => {

  const renderProducts = () => {
    return products
      .map(({ href, name, tagline, image, labels }, idx) => {
        const picture = { model: image, profile: productCardPictureProfile };
        return (
          <SimpleTile
            key={idx}
            href={href}
            title={name}
            description={tagline}
            picture={picture}
            labels={labels}
          />
        );
      });
  };

  return (
    <div
      className={classNames("ProductsListingProducts", {
        [`ProductsListingProducts--fullWidth`]: fullWidth,
      })}
    >
      <div className="ProductsListingProducts__gridWrapper">
        {showNativeProductsCount && (
          <Count count={products.length} label="products" variant="bold" />
        )}
        <div className="ProductsListingProducts__grid">{renderProducts()}</div>
      </div>
      <div className="ProductsListingProducts__paginationWrapper">
        <span className="ProductsListingProducts__paginationText">
          Showed {products.length} products
        </span>
      </div>
    </div>
  );
};
